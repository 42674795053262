.help {
  justify-content: center;
  background-color: #e1e1e1;
  max-height: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  box-shadow:  5px 0 25px rgba(0, 0, 0, 0.5),
  -5px 0 25px rgba(0, 0, 0, 0.5);
}

.help__title-main {
  font-size: 36px;
  color: #000;
  text-align: center;
  margin: 57px auto 24px auto;
  font-family: 'Geologica Roman';
  font-style: normal;
  font-weight: 600;
  /* font-family: 'Bad Script', cursive; */
}

.help__title {
  font-size: 28px;
  color: #000;
  padding-left: 40px;
  padding-right: 40px;
  /* font-family: 'Bad Script', cursive; */
  line-height: 45px;
  margin-bottom: 0;
  /* font-weight: bold; */
  text-decoration: underline;
  font-family: 'Geologica Roman';
  font-style: normal;
  font-weight: 600;

}

.help__title-middle {
  font-size: 28px;
  color: #000;
  padding-left: 40px;
  padding-right: 40px;
  /* font-family: 'Bad Script', cursive; */
  line-height: 45px;
  margin-bottom: 0;
  font-family: 'Geologica Roman';
  font-style: normal;
  font-weight: 300;
}

.centrate {
  margin-bottom: 30px;

}

.help-list {
  list-style-type: none;
}




.help__list-item {
  color: #000;
  font-size: 28px;
  margin-left: 40px;
  margin-right: 40px;
  /* font-family: 'Bad Script', cursive; */
  line-height: 45px;
  list-style-type: none;
  font-family: 'Geologica Roman';
  font-style: normal;
  font-weight: 300;

}

.help__list-item:before {
  content: "-";
  margin-right: 0.5em;
}

.help__title-bottom {
  font-size: 36px;
  color: #000;
  text-align: center;
  margin: 30px auto 50px auto;
  font-family: 'Geologica Roman';
  font-style: normal;
  font-weight: 600;
  /* font-family: 'Lobster', cursive; */
}

.logo_help {
  left: 17%;
}

@media screen and (max-width: 1000px) {
  .help__title-main {
    font-size: 30px;
    margin: 35px auto 20px auto;
  }
  
  .help__title {
    font-size: 26px;
  }

  .help__title-middle {
    font-size: 26px;
  }
  
  .help__list-item {
    font-size: 26px;
  }
  
  .help__title-bottom {
    font-size: 30px;
  }
}


@media screen and (max-width: 760px) {
  .help {
    padding: 0;
  }
  
  .help__title-main {
    font-size: 26px;
  }
  
  .help__title {
    font-size: 18px;
    line-height: 30px;
  }
  

  .help__title-middle {
    font-size: 18px;
    line-height: 30px;
  }
  
  .help__list-item {
    font-size: 18px;
    line-height: 30px;
  }
  
  .help__title-bottom {
    font-size: 26px;
  }
}

@media screen and (max-width: 560px) {
  .help {
    padding: 0;
  }

  .help__title-main {
    font-size: 22px;
  }
  
  .help__title {
    font-size: 16px;
  }

  .help__title-middle {
    font-size: 16px;
  }
  
  .help__list-item {
    font-size: 16px;

  }
  
  .help__title-bottom {
    font-size: 22px;
  }

  .logo_help {
    left: 17%;
  }
}



@media screen and (max-width: 400px) {
  .help__title-main {
    font-size: 18px;
  }
  
  .help__title {
    font-size: 14px;
    line-height: 25px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 10px auto;
  }

  .help__title-middle {
    font-size: 14px;
    line-height: 25px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 10px auto;
  }

  .help__list-item {
    font-size: 14px;
    line-height: 25px;
  }
  
  .help__title-bottom {
    font-size: 18px;
  }

  .help__list {
    padding-left: 15px;
  }
}
