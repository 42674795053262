.app {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  background: linear-gradient(#EAEAEA, #ffffff);
  font-family: Inter, Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  z-index: -5;  
}
