.main {
  max-width: 1440px;
  height: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  box-shadow:  5px 0 25px rgba(0, 0, 0, 0.5),
              -5px 0 25px rgba(0, 0, 0, 0.5);
}


.main__underphoto {
  background-color: #43474A;
  width: 100%;
  height: 270px;
  display: flex;
  background: linear-gradient(to top, rgba(67, 71, 74, 0.9) 70%, #42474b 90%);
  position: relative;
  /* top: 0px; */
}

/* .block_height {
  height: 170px;
} */

.main__image-container {
  position: relative;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  object-fit: cover;
  overflow: hidden;
}

.main__header,
.main__subtitle {
  margin: 0;
}

.main__header {
  position: absolute;
  font-size: 25px;
  width: 400px;
  height: 137px;
  top: 40%;
  left: 19%;
  transform: translate(-50%, -50%);
}

.main__header-image {
  width: 100%;
  height: 100%;
  display: block;
}

.main__picture {
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.main__description-list {
  font-size: 18px;
  color: white;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  max-width: 80%;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  margin: auto;
  z-index: 4;
  position: relative;
}

.main__description-block {
  max-width: 50%;
  display: flex;
  flex-direction: column;
}

.main__description-title {
  /* font-family: 'Lobster', cursive; */
  font-weight: bolder;
  color: #8f5531;
  font-size: 28px;
  text-align: center;
  /* font-weight: bold; */
  margin-top: 0px;
  font-family: 'Geologica Roman';
  font-style: normal;
  font-weight: 600;
}

.main__description-subtitle {
  /* font-family: 'Bad Script', cursive; */

  color: #FFFFFF;
  font-size: 21px;
  text-align: center;
  /* font-weight: bold; */
  margin-top: auto;
  margin-bottom: 0px;
  font-family: 'Geologica Roman';
  font-style: normal;
  font-weight: 500;
}

.auto_margin {
  margin-top: auto;
  margin-bottom: auto;
}


@media screen and (max-width: 1000px) {
  .main__header {
    width: 300px;
    height: 137px;
  }
  

  
  .main__description-list {
    font-size: 16px;
    column-gap: 18px;
    width: 90%;
  }

  .main__description-title {
    font-size: 24px;
  }

  .main__description-subtitle {
    font-size: 17px;
  }
}

@media screen and (max-width: 810px) {
  .main__description-list {
    font-size: 14px;
    column-gap: 16px;
    max-width: 800px;
    width: 90%;
  }

}

@media screen and (max-width: 720px) {
  .main__header {
    left: 20%;

    width: 250px;
    height: 110px;
  }

  .main__description-list {
    column-gap: 10px;
    max-width: 720px;
    width: 90%;
  }


  .main__description-title {
    font-size: 20px;
  }

  .main__description-subtitle {
    font-size: 13px;
  }




  .main__image-container {
    height: 550px;
  }

  .main__underphoto {
    height: 190px;
  }

}


@media screen and (max-width: 600px) {
  .main__header {
    width: 210px;
    height: 90px;
  }

  .main__description-list {
    bottom: 2%;
  }

  /* .main__description-title {
    margin-top: 0;
  } */

  .main__image-container {
    height: 430px;
  }

  /* .main__underphoto {
    height: 170px;
  } */
}


@media screen and (max-width: 560px) {
  .main__header {
    width: 175px;
    height: 70px;
  }

  .main__description-title {
    font-size: 15px;
  }

  .main__description-subtitle {
    font-size: 11px;
  }

  .main__image-container {
    height: 350px;
  }

  .main__underphoto {
    height: 170px;
  }
}


@media screen and (max-width: 400px) {
  .main__header {
    width: 125px;
    height: 55px;
  }

  .main__description-title {
    font-size: 14px;
  }

  .main__description-subtitle {
    font-size: 9px;
  }

  .main__image-container {
    height: 300px;
  }

  /* .main__underphoto {
    height: 60px;
  } */
}
