.volunteers {
  justify-content: center;
  padding: 0 0 30px 0;
  background: linear-gradient(#f1f1f1, #cbcbcb);
  max-height: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow:  5px 0 25px rgba(0, 0, 0, 0.5),
  -5px 0 25px rgba(0, 0, 0, 0.5);

}


.volunteers__container {
  margin: auto;
  padding: 0 90px;
  display: flex;
  justify-content: space-between;
  column-gap: 60px;
}

.volunteers__picture {
  border-radius: 35px;
  overflow: hidden;
}

.volunteers__picture img {
  width: 100%;
  height: 100%;
}

.volunteers__picture--first {
  flex-basis: 80%;
}

.volunteers__picture--second {
  flex-basis: 35%;
}

.volunteers__image {
  border-radius: 35px;
  overflow: hidden;
}

.volunteers__image img {
  width: 100%;
  height: 100%;
}

.volunteers__image--first {
  flex-basis: 30%; 
}

.volunteers__image--second {
  flex-basis: 30%; 
}

.volunteers__image--third {
  flex-basis: 55%; 
}



.volunteers__title-main {
  font-size: 36px;
  color: #000;
  text-align: center;
  margin: 40px auto 40px auto;
  padding: 0 90px;
  font-family: 'Geologica Roman';
  font-style: normal;
  font-weight: 600;
  /* font-family: 'Bad Script', cursive; */
  /* font-family: 'Lobster', cursive; */
}

.volunteers__title {
  font-size: 30px;
  color: #000;
  text-align: justify;
  padding: 40px 90px;
  max-width: 1060px;
  margin: 0 auto;
  font-family: 'Geologica Roman';
  font-style: normal;
  font-weight: 300;
  /* font-family: 'Bad Script', cursive; */
}

.logo__title {
  font-size: 42px;
  position: absolute;
  bottom: 16%;
  left: 49%;
  transform: translate(-50%, -50%);
  /* font-family: 'Bad Script', cursive; */
  font-family: 'Lobster', cursive;
}

.volunteers__container-second {
  margin: 0 auto;
  padding: 0 90px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 40px;
  max-width: 95%;
}

@media screen and (max-width: 950px) {
  .volunteers__container {
    column-gap: 40px;
  }

  .volunteers__container-second {
    column-gap: 40px;
  }

  .volunteers__title {
    font-size: 28px;
  }

  .logo__title {
    font-size: 32px;
    bottom: 16%;
    left: 49%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 767px) {
  .volunteers__container {
    column-gap: 30px;
    padding: 0 50px;
  }

  .volunteers__container-second {
    column-gap: 20px;
    padding: 0 50px;
  }

  .volunteers__title {
    font-size: 22px;
    padding: 40px 50px;

  }

  .logo__title {
    font-size: 28px;
    bottom: 16%;
    left: 49%;
    transform: translate(-50%, -50%);
  }

  .volunteers__title-main {
    font-size: 30px;
    margin: 40px auto 40px auto;
    padding: 0 50px;
  }
}

@media screen and (max-width: 560px) {
  .volunteers__title {
    font-size: 20px;
    padding: 40px 40px;
  }

  .volunteers__title-main {
    font-size: 24px;
    padding: 0 40px;
  }

  .volunteers__container {
    column-gap: 30px;
    padding: 0 40px;
  }

  .logo__title {
    font-size: 24px;
    bottom: 19%;
    left: 49%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 470px) {
  .volunteers__title {
    font-size: 16px;
    padding: 20px 30px;
  }

  .volunteers__title-main {
    font-size: 22px;
    padding: 0 30px;
  }

  .volunteers__container {
    column-gap: 20px;
    padding: 0 30px;
  }

  .volunteers__container-second {
    column-gap: 10px;
    padding: 0 30px;
  }

  .logo__title {
    font-size: 18px;
    bottom: 21%;
    left: 49%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 350px) {
  .volunteers__title-main {
    font-size: 18px;
    padding: 0 20px;
  }

  .logo__title {
    font-size: 18px;
    bottom: 20%;
    left: 49%;
    transform: translate(-50%, -50%);
  }
}

