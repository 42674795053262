.about {
  max-width: 1440px;
  margin: 0 auto;
  max-height: 100%;
  background-color: #e1e1e1;
  box-shadow: 5px 0 25px rgba(0, 0, 0, 0.5),
              -5px 0 25px rgba(0, 0, 0, 0.5);
}

.about__title {
  color: #000;
  text-align: center;
  font-size: 62px;
  margin: 0;
  padding-bottom: 50px;
  padding-top: 10px;
  
  

}

.about__photo {
  max-width: 100%;
  flex-direction: row;
  display: flex;
  padding: 0 106px;
  justify-content: space-between;
  
}

.about__employee-card {
  max-width: 100%;
  max-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about__employee-description {
  color: #000;
  text-align: center;
  font-size: 40px;
  margin: 41px 0 0 0;
}


.about__employee-photo {
  width: 360px;
  height: 360px;
  box-sizing: border-box;
  object-fit: cover;
  border-radius: 80%;
  margin: auto;
}

.about__subtitle {
  color: #000;
  text-align: center;
  font-size: 32px;
  margin-top: 0;
  padding-top: 25px;
}

.about__first-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 93px 0 93px;
  column-gap: 50px;
}

.about__first-block_reverse {
  flex-direction:row-reverse;
  padding-bottom: 40px;

}

.about__first-block-title {
  color: #000;
  display: flex;
  align-items: center;
  font-size: 28px;
  line-height: 40px;
  text-align: justify;
  max-width: 44%;
  font-family: 'Geologica Roman';
  font-style: normal;
  font-weight: 200;
  /* font-family: 'Bad Script', cursive; */
  /* font-weight: bold; */
}

.about__image {
  max-width: 388px;
  max-height: 297px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  object-fit: cover;
  margin: auto 0 auto auto;
}


.about__image-centrate {
  margin: auto auto auto 0;
}


.about__first-block-image {
  max-width: 388px;
  max-height: 297px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  object-fit: cover;
  border-radius: 30%;
}

.about__targets {
  padding: 10px 93px 25px 93px;
  background-color: #43474A;
}

.about__targets-title {
  color: #000;
  font-size: 32px;
  text-align: center;
  color: #8f5531;
  /* font-family: 'Lobster', cursive; */
  font-family: 'Geologica Roman';
  font-style: normal;
  font-weight: 600;
  
}

.about__targets-description {
  color: #000;
  font-size: 28px;
  color: #ffffff;
  text-align: justify;
  /* font-family: 'Bad Script', cursive; */
  /* font-weight: bold; */
  font-family: 'Geologica Roman';
  font-style: normal;
  font-weight: 200;
}

.about__find {
  padding: 0 93px 0 93px;
  background-color: #e1e1e1;
}

.about__find-title {
  color: #000;
  font-size: 32px;
  text-align: center;
  padding-top: 30px;
  margin-top: 0;
  /* font-family: 'Lobster', cursive; */
  font-family: 'Geologica Roman';
  font-style: normal;
  font-weight: 500;
}

.about__find-description {
  color: #000;
  font-size: 28px;
  margin-bottom: 0px;
  padding-bottom: 25px;
  text-align: justify;
  font-family: 'Geologica Roman';
  font-style: normal;
  font-weight: 300;
  /* font-family: 'Bad Script', cursive; */
  /* font-weight: bold; */
}


.about__map {
  margin: 0 auto;
  max-width: 100%;
  padding: 20px 93px 68px 93px;
}

.about__map-image {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1000px) {


  .about__first-block-title {
    /* font-size: 20px; */
    line-height: 35px;
  }

  .about__targets-description {
    font-size: 24px;
  }
  
  /* .about__find-description {
    font-size: 24px;
  } */

}

@media screen and (max-width: 900px) {
  .about__first-block {
    column-gap: 40px;
    padding-top: 20px;
  }
  
  .about__first-block-title {
    font-size: 24px;
    line-height: 35px;
  }

  .about__image {
    max-width: 280px;
    max-height: 280px;
  }

  .about__first-block_reverse {
    padding-top: 20px;
  
  }
}

@media screen and (max-width: 720px) {
  .about__find {
    padding: 0 60px 0 60px;
  }

  .about__first-block {
    padding: 20px 60px 0 60px;
  }

  .about__first-block_reverse {
    padding-bottom: 40px;
    padding-top: 20px;
  
  }

  .about__first-block-title {
    font-size: 20px;
    line-height: 30px;
  }

  .about__targets-description {
    font-size: 20px;
  }
  
  .about__find-description {
    font-size: 20px;
    padding-bottom: 0;

  }

  .about__targets-title {
    font-size: 26px;
  }

  .about__find-title {
    font-size: 26px;
  }

  .about__map {
    padding: 30px 60px 68px 60px;
  }

  .about__targets {
    padding: 10px 60px 25px 60px;
  }
}

@media screen and (max-width: 600px) {

  .about__first-block-title {
    font-size: 18px;
    line-height: 22px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .about__targets-description {
    font-size: 18px;
  }
  
  .about__find-description {
    font-size: 18px;
  }

  .about__targets-title {
    font-size: 20px;
  }

  .about__find-title {
    font-size: 20px;
  }
}



@media screen and (max-width: 500px) {
  .about__first-block {
    padding-left: 20px;
    padding-right: 20px;
    column-gap: 20px;
  }

  .about__targets {
    padding: 10px 30px 25px 30px;

  }

  .about__find {
    padding: 0 30px 0 30px;
  }

  .about__map {
    padding: 30px 30px 40px 30px;
  }
}

@media screen and (max-width: 400px) {
  .about__first-block-title {
    font-size: 12px;
    line-height: 16px;
  }

  .about__targets-title {
    font-size: 16px;
  }

  .about__targets-description {
    font-size: 12px;
  }

  .about__find-title {
    font-size: 16px;
  }

  .about__find-description {
    font-size: 12px;
  }
}
