.header {
  margin: 0 auto;
  padding: 0 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 74px;
  max-width: 1440px;
  box-sizing: border-box;
  background: linear-gradient(to right,#9c6846, #FFFFFF);
  box-shadow: 5px 0 25px rgba(0, 0, 0, 0.5),
              -5px 0 25px rgba(0, 0, 0, 0.5);
}



.header__link {
  display: block;
  width: 83.65px;
  height: 70px;
}

.header__logo {
  margin: auto;
  width: 100%;
  height: 100%;
}

.header__logo:hover {
  opacity: 0.5;
}

.header__list-links {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  text-decoration: none;
  box-sizing: border-box;
}

.header__list-link {
  padding: 8px 30px;
  font-style: normal;
  font-weight: 500;
  color: #000000;
  font-size: 22px;
  line-height: 16px;
  display: block;
  border-radius: 6px;
  text-decoration: none;
  font-family: 'Lobster', cursive;
}

.header__list-link:hover {
  opacity: 0.5;
}

.header__list-link_type_button {
  color: #FFFFFF;
}

.header__button {
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 76px;
  height: 32px;
  border-radius: 3px;
  border: none;
  background-color: #3456f3;
}

@media screen and (max-width: 1000px) {
  .header__list-link {
    font-size: 18px;
    padding: 8px 14px;
  }
}

@media screen and (max-width: 810px) {
  .header {
    padding: 0 30px;
  }

  .header__list-link {
    font-size: 14px;

  }
}

@media screen and (max-width: 600px) {
  .header {
    padding: 0 14px;
    min-height: auto;
  }
  
  .header__list-link {
    font-size: 12px;
    padding: 8px 14px;
  }

  .header__link {
    width: 50px;
    height: 50px;
  }

  .header__logo {
    margin: auto;
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 500px) {
  .header__list-link {
    padding: 5px 5px;
    font-size: 10px;
  }
}

@media screen and (max-width: 500px) {
  .header__list-link {
    padding: 5px 5px;
    font-size: 8px;
  }
}
