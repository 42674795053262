@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url('./Inter-Regular.woff2') format('woff2'),
       url('./Inter-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url('./Inter-Medium.woff2') format('woff2'),
       url('./Inter-Medium.woff') format('woff');
}


@font-face {
  font-family: 'Bad Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./BadScript.woff2') format('woff2');
  /* unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; */
}

@font-face {
  font-family: 'Lobster';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./Lobster.woff2') format('woff2');
  /* unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; */
}


@font-face {
  font-family: 'Geologica Roman';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src:
    url('./GeologicaRoman/GeologicaRoman-Light.woff2') format('woff2'),
    url('./GeologicaRoman/GeologicaRoman-Light.woff') format('woff');
}

@font-face {
  font-family: 'Geologica Roman';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    url('./GeologicaRoman/GeologicaRoman-Regular.woff2') format('woff2'),
    url('./GeologicaRoman/GeologicaRoman-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Geologica Roman';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src:
    url('./GeologicaRoman/GeologicaRoman-Medium.woff2') format('woff2'),
    url('./GeologicaRoman/GeologicaRoman-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Geologica Roman';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src:
    url('./GeologicaRoman/GeologicaRoman-SemiBold.woff2') format('woff2'),
    url('./GeologicaRoman/GeologicaRoman-SemiBold.woff') format('woff');
}
