.card {
  position: relative;
  width: 270px;
  height: 310px;
  margin: auto;
}

.card .face{
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 10px;
  overflow: hidden;
  transition: 1.5s;
}

.card .front{
  transform: perspective(600px) rotateY(0deg);
  box-shadow: 0 5px 10px #000;
}
.card .front img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card .front h3 {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 45px;
  line-height: 45px;
  color: #fff;
  background: rgba(0, 0, 0, 0.4);
  text-align: center;
  /* font-family: 'Bad Script', cursive; */
  /* font-family: 'Lobster', cursive; */
  font-family: 'Geologica Roman';
  font-style: normal;
  font-weight: 600;

}

.card .back {
  transform: perspective(600px) rotateY(180deg);
  background-color: #ff3cac;
  background-image: linear-gradient(
    225deg,
    /* #ff3cac 0%,
    #784ba0 50%,
    #2b86c5 100% */
    #fca138 0%,
    #a2a3a3 50%,
    #2b86c5 100%
  );
  color: #f3f3f3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  box-shadow: 0 5px 10px #000;
}

.card .back .link {
  border-top: solid 1px #f3f3f3;
  height: 50px;
  line-height: 50px;
}

.card .back .link a {
  color: #f3f3f3;
  text-decoration: none;
  transition: 0.2s;
  font-size: large;
  letter-spacing: 1.5px;
}

.card .back .link a:hover {
  color: #000;
}

.card .back h3 {
  font-size: 30px;
  margin-top: 20px;
  letter-spacing: 2px;
}

.card .back p {
  letter-spacing: 1.15px;
}

.card:hover .front {
  transform: perspective(600px) rotateY(180deg);
}

.card:hover .back {
  transform: perspective(600px) rotateY(360deg);
}

.card__pic {
  display: block;
  width: 100%;
  height: 282px;
  border-radius: 10px 10px 0 0;
  object-fit: cover;
  overflow: hidden;
  transition: opacity .3s ease-in;
}

.link {
  font-family: 'Geologica Roman';
  font-style: normal;
  font-weight: 300;
}



