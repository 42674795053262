
.cats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(282px, 1fr));
  justify-content: center;
  padding-top: 50px;
  padding: 50px 0 50px 0;
  row-gap: 20px;
  column-gap: 18px;
  background-image: url(../../images//mona-eendra-vC8wj_Kphak-unsplash.jpg);
  max-height: 100%;
  max-width: 1080px;
  margin: 0 auto;
  box-shadow:  5px 0 25px rgba(0, 0, 0, 0.5),
  -5px 0 25px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 767px) {
  .cats {
    padding: 36px 30px 0;
  }
}

@media screen and (max-width: 560px) {
  .cats {
    padding: 36px 19px 0;
  }
}