.footer {
  background: linear-gradient(to right,#9c6846, #FFFFFF);
  box-shadow:  5px 0 25px rgba(0, 0, 0, 0.5),
  -5px 0 25px rgba(0, 0, 0, 0.5);
  margin: auto;
  padding: 20px 45px;
  display: flex;
  max-width: 1350px;
  flex-direction: column;
  border-top: 1px solid #000;
}

.footer__title {
  color: #000;
  margin: 30px auto 20px auto;
  font-size: 24px;
  font-family: 'Lobster', cursive;
}

.footer__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
}

.footer__logo {
  width: 70px;
  height: 70px;
  align-items: center;
  display: flex;
  margin: auto 0 auto 0;
  border-radius: 80%;
  background-color: transparent;
  object-fit: fill;
}

.footer__contacts {
  display: flex;
  flex-direction: column;
  margin: auto auto auto 20px;
  /* font-family: 'Bad Script', cursive; */
  font-family: 'Geologica Roman';

}

.footer__contacts-block {
  display: flex;
  flex-direction: row;
}

.footer__contacts-title {
  color: #000;
  font-size: 24px;
  font-family: 'Lobster', cursive;
  
}

.footer__contacts-icon {
  width: 25.29px;
  height: 30px;
  margin: auto 0 auto 0;
}

.footer__contacts-phone {
  color: #000;
  margin: auto 0 auto 0;
}

.footer__contacts-mail {
  color: #000;
}

.footer__social {
  display: flex;
  flex-direction: row;
  margin: auto 0;
  justify-content: space-between;
}

.footer__social-icons {
  list-style: none;

}

.footer__social-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 5px;
}



.footer__social-title {
  margin-right: 45px;
  color: #000;
  margin: auto;
  font-size: 24px;
  font-family: 'Bad Script', cursive;

}

.footer__social-icon {
  width: 60px;
  height: 60px;
}

@media screen and (max-width: 1000px) {
  .footer__title {
    text-align: center;
    font-size: 19px;
    margin: 30px auto 20px auto;
  }

  .footer__contacts-title {
    font-size: 18px;
  }

  .footer__social-title {
    font-size: 18px;
  }
}

@media screen and (max-width: 720px) {
  .footer__title {
    font-size: 16px;
    margin: 10px auto 10px auto;
  }

  .footer__contacts-title {
    font-size: 14px;
  }

  .footer__social-title {
    font-size: 14px;
  }

  .footer__contacts-phone {
    font-size: 10px;
  }
  
  .footer__contacts-mail {
    font-size: 10px;
  }

  .footer__social-icon {
    width: 40px;
    height: 40px;
  }

  .footer__logo {
    width: 50px;
    height: 50px;
  }

  .footer__social-icons {
    padding: 0 0 0 10px;
  }
}

@media screen and (max-width: 560px) {
  .footer {
    padding: 20px 30px;
  }


  .footer__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }


  .footer__title {
    font-size: 16px;
  }

  .footer__social {
    margin: auto auto auto 0;
    justify-content: start;
  }

  .footer__social-title {
    font-size: 20px;
  }

  .footer__contacts {
    display: flex;
    flex-direction: column;
    margin: auto auto auto 0;
  }

  .footer__contacts-title {
    font-size: 18px;
  }

  .footer__contacts-phone {
    font-size: 18px;
  }
  
  .footer__contacts-mail {
    font-size: 18px;
  }

  .footer__social-icon {
    width: 40px;
    height: 40px;
  }

  .footer__logo {
    display: none;
    width: 30px;
    height: 30px;
  }

  .footer__social-icons {
    padding: 0 0 0 10px;

  }
}


@media screen and (max-width: 400px) {

  .footer__title {
    font-size: 15px;
  }

  .footer__social-title {
    font-size: 18px;
  }

  .footer__contacts-title {
    font-size: 16px;
  }

  .footer__contacts-phone {
    font-size: 16px;
  }

  .footer__contacts-mail {
    font-size: 16px;
  }
}




