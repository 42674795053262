.logo {
  max-width: 1440px;
  max-height: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  box-shadow:  5px 0 25px rgba(0, 0, 0, 0.5),
  -5px 0 25px rgba(0, 0, 0, 0.5);
  /* background: #f1f1f1; */
  /* border-bottom: 2px solid #000; */
  background: rgba(67, 71, 74, 0.9);

}

.logo__logo-container {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.logo__picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.logo__header {
  font-size: 30px;
  position: absolute;
  top: 11%;
  left: 16%;
  width: 294px;
  height: 114px;
  transform: translate(-50%, -50%);

}

.logo__description-list {
  background: transparent;
  position: absolute;
  bottom: 2%;
  left: -3%;
  transform: translate(6%, 5%);
  color: white;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  max-width: 95%;
  margin: 0 auto 0 auto;
  justify-content: space-between;
}

.logo__description-block {
  max-width: 49%;
}

.logo__description-title {
  color: #8f5531;
  font-size: 16px;
  text-align: center;
  font-family: 'Lobster', cursive;
}

.logo__description-subtitle {
  color: #FFFFFF;
  font-size: 14px;
  text-align: center;
  font-family: 'Bad Script', cursive;

}

.dog-section-size {
  max-width: 50%;
}

.no-margin {
  margin-bottom: 0;
}


.logo__title-main {
  font-size: 36px;
  color: #000;
  text-align: center;
  margin: 57px auto 24px auto;
  font-family: 'Bad Script', cursive;
}


@media screen and (max-width: 900px) {
  .logo__header {
    width: 280px;
    height: 114px;
  }

  .logo__description-title {
    font-size: 14px;
  }
  
  .logo__description-subtitle {
    font-size: 12px;
  }

}

@media screen and (max-width: 780px) {
  .logo__header {
    width: 220px;
    height: 90px;
  }

  .logo__description-title {
    font-size: 12px;
  }
  
  .logo__description-subtitle {
    font-size: 10px;
  }

}

@media screen and (max-width: 641px) {
  .logo {
    height: 100%;
  }

  /* .logo__logo-container {
    height: 400px;  
  } */

  .logo__picture {
    width: 100%;
    height: 100%;
  }
  
  
  .logo__header {
    width: 175px;
    height: 70px;
    left: 16%;
  }

  .logo__description-title {
    font-size: 10px;
  }
  
  .logo__description-subtitle {
    font-size: 8px;
  }

}

@media screen and (max-width: 530px) {  
  .logo__header {
    width: 110px;
    height: 50px;
    /* left: 84%; */
    left: 15%;

    top: 12%;
  }

  .logo__description-title {
    font-size: 9px;
  }
  
  .logo__description-subtitle {
    font-size: 7px;
  }

  /* .logo__logo-container {
    height: 350px;  
  } */

}

@media screen and (max-width: 450px) {
  .logo__description-title {
    font-size: 8px;
  }
  
  .logo__description-subtitle {
    font-size: 6px;
  }

  .logo__header {

    /* left: 19%; */
    top: 12%;
  }
}


@media screen and (max-width: 400px) {  
  .logo__header {
    width: 90px;
    height: 40px;
    left: 14%;
    top: 12%;
  }
}
