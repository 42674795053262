.dogs {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(282px, 1fr));
  justify-content: center;
  padding-top: 50px;
  padding: 50px 0 50px 0;
  row-gap: 20px;
  column-gap: 18px;
  background: linear-gradient(#f1f1f1, #cbcbcb);
  max-height: 100%;
  max-width: 1440px;
  margin: 0 auto;
  /* box-shadow:  5px 0 25px rgba(0, 0, 0, 0.5),
  -5px 0 25px rgba(0, 0, 0, 0.5); */


  /* box-shadow: 5px 0 25px rgba(0, 0, 0, 0.5),
            -5px 0 25px rgba(0, 0, 0, 0.5),
            0 5px 25px rgba(0, 0, 0, 0.5),
            0 -5px 0 rgba(0, 0, 0, 0); */


  box-shadow: 5px 16px 25px rgba(0, 0, 0, 0.5), 
              -5px 13px 25px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;

}

@media screen and (max-width: 767px) {
  .dogs {
    padding: 36px 30px;
  }
}

@media screen and (max-width: 560px) {
  .dogs {
    padding: 36px 19px;
  }
}